/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyD2fpa8dy8hAdu2GYiNfeZ6p4l2nRJRPD0",
  "appId": "1:853332638122:web:488d2791de765b313ed3d3",
  "authDomain": "schooldog-i-bryan-ga.firebaseapp.com",
  "measurementId": "G-LBJC8PGVFT",
  "messagingSenderId": "853332638122",
  "project": "schooldog-i-bryan-ga",
  "projectId": "schooldog-i-bryan-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-bryan-ga.appspot.com"
}
